<template>
  <div class="row mx-0 justify-content-center">
    <div class="col-12 mb-3 text-center font19" >
      Select Payment Method
    </div>
    <div class="col-12 mb-3 text-center font12 bold">
      <div class="row mx-0 justify-content-center">
        <div class="col-10 col-md-6 col-xl-5" >
          Please select the payment method you would like to use for this donation:
        </div>
      </div>
    </div>
    <div class="col-12 col-md-9 mb-3 text-center">
      <div class="row mx-0 justify-content-center">
        <div class="col-md-6 mb-3">
          <card icon="icon" title="debit order" heading="Bank" @click="selectPaymentMethod('DEBIT_ORDER')" class="cursor" :selected="value === 'DEBIT_ORDER'">
            <IconBank color="gold" size="size28" />
          </card>
        </div>
        <div class="col-md-6 mb-3">
          <card icon="icon"  title="online setup" heading="Card" @click="selectPaymentMethod('CREDIT_CARD')" class="cursor" :selected="value === 'CREDIT_CARD'">
            <IconCalendarFull color="gold" size="size28" />
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Daily Sadaqah Select Payment Method',
  components: {
    Card: defineAsyncComponent(() => import('@/views/CardSelect.vue')),
    IconBank: defineAsyncComponent(() => import('@/components/icons/IconBank.vue')),
    IconCalendarFull: defineAsyncComponent(() => import('@/components/icons/IconCalendarFull.vue'))
  },
  props: {
    modelValue: {
      type: String,
      required: true
    }
  },
  emits: ['update:modelValue', 'methodSelected'],
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    async selectPaymentMethod (val) {
      this.value = val
      setTimeout(() => {
        this.$emit('methodSelected')
      }, 500)
    }
  }
}
</script>
